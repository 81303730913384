import { v4 as uuidv4 } from 'uuid';

window.gon = {
    config: {
        // Auth0 configuration values
        AUTH0_API_CLIENT_ID: 'dpm1UWlh4dK5J29LIPMvKHZY7VE6pn49',
        AUTH0_API_CLIENT_SECRET: '<%= decrypt "dNovT0w03LqjfqUqmQA/KNJXh6xlE4wnUO4e/lA7G0JfQ6ryzzzEHz741/xG1JHdDRHWJIP6xd1B6nJvNSA7leiSanlDGJlc1bpGHAwi2rEOmymz3zqMxg==" %>',
        AUTH0_DOMAIN_URL: 'https://stg-patientfusion.auth0.com',
        PATIENT_PORTAL_AUTH0_CLIENT_ID: 'lZdeIGINDpvPfQKe026j8KTPPtBPu0z2',
        PHR_AUTH_TOKEN_KEY: 'phr_auth_token',

        // UI configuration values
        AGREEMENT_ENDPOINT_URL: 'https://stg-agreement-endpoint.patientfusion.com/api/v1',
        BILLING_ENDPOINT_URL: 'https://stg-billing-endpoint.patientfusion.com/api/v1',
        CLINICAL_ENDPOINT_URL: 'https://stg-clinical-endpoint.patientfusion.com/api/v1',
        INTAKE_ENDPOINT_URL: 'https://stg-intake-endpoint.patientfusion.com/api/v2',
        INTAKE_LOGIN_URL: 'https://stg-intake.patientfusion.com',
        PHR_HOME_URL: 'https://stg-my.patientfusion.com',
        PHR_PATIENT_ENDPOINT_URL: 'https://stg-phr-patient-endpoint.patientfusion.com/api/v1',
        PHR_TOKEN_ENDPOINT_URL: 'https://stg-phr-authn-endpoint.patientfusion.com/api/v3',
        PF_LOGIN_URL: 'https://stg-login.patientfusion.com'
    },
    pfConfig: {
        EHR_INTAKE_URL: 'https://stg-intake.practicefusion.com',
        EHR_TOKEN_ENDPOINT_URL: 'https://stg.practicefusion.com/IntakeEndpoint/api/v1',
    },
    vnextConfig: {
        EHR_INTAKE_URL: 'https://stg-intake.veradigmview.com',
        EHR_TOKEN_ENDPOINT_URL: 'https://stg.veradigmview.com/IntakeEndpoint/api/v1',
    },
    env: 'staging',
    sessionKey: uuidv4()
};